enum SUMSUB_STATUS {
  NOT_INITIATED = 'NOT_INITIATED',
  CREATED = 'CREATED',
  RESETED = 'RESETED',
  PENDING = 'PENDING',
  ACTION_PENDING = 'ACTION_PENDING',
  ON_HOLD = 'ON_HOLD',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  RETRY = 'RETRY',
  IN_REVIEW = 'IN_REVIEW', //extra field just for UI
}

enum PROJECT_STATUSES {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  COMPLETED = 'completed',
  CREATING_TOKENS = 'creating',
  DEVELOPMENT_COMPLETED = 'development_completed',
  DRAFT = 'draft',
  PAUSED = 'paused',
  PROJECT_COMPLETED = 'project_completed',
  TOKENS_DELIVERED = 'delivered',
}
enum INVESTMENT_STATUSES {
  PENDING = 'pending',
  RECEIVED = 'received',
  REJECTED = 'rejected',
  COMMITTED = 'committed',
  TO_REFUND = 'to_refund',
  REFUNDED = 'refunded',
  MANUALLY_REFUNDED = 'manually_refunded',
  REFUND_INPROCESS = 'refund_inprocess',
  DELAYED = 'delayed',
}

const SUMSUB_URL = 'https://cockpit.sumsub.com/checkus#/applicant/';

enum API_ERRORS {
  CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
  JOI_ERROR = 'JOI_ERROR',
  LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  NOT_FOUND_EXCEPTION = 'NotFoundException',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
  USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
  INVALID_REQUEST_EXCEPTION = 'InvalidRequestException',
  UNKNOWN_ERROR = 'UnknownError',
  BLACKLISTED_BROKER_EXCEPTION = 'BlacklistedBrokerException',
  INVALID_ASSOCIATION = 'InvalidAssociationException',
}

enum ENVS {
  DEV = 'DEV',
  PROD = 'PROD',
  STG = 'STG',
}

const siteDomains = {
  'admin.growie.io': ENVS.PROD,
  'dev.admin.frtnglb8532.net': ENVS.DEV,
  'localhost:3000': ENVS.DEV,
  'stg.admin.frtnglb8532.net': ENVS.STG,
};

export const getEnv = () => siteDomains[window.location.host as keyof typeof siteDomains];

export { API_ERRORS, ENVS, INVESTMENT_STATUSES, PROJECT_STATUSES, SUMSUB_STATUS, SUMSUB_URL };
